body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  /* margin-top: 0.6rem; */
  margin-right: 0.5rem;
}

.load-progress-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease;
  z-index: 1000;
}

.load-progress-inner {
  flex-grow: 1;
  flex-shrink: 0;
  height: 14px;
  background: #272727;
  text-align: center;
  position: relative;
}

.load-progress-bar {
  height: 100%;
  width: 100%;
  background: white;
  transition: transform 200ms;
  transform-origin: left center;
  margin-bottom: 0.5rem;
}

.load-progress-data {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  color: #f0f0f0;
  mix-blend-mode: difference;
  font-size: 0.6em;
  white-space: nowrap;
}